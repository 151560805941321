import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const LegalProfessionalsCoachingPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "16.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/profile_stuart_long.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				benG: file(relativePath: { eq: "testimonials/Ben Gottlieb.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Legal Professionals | Business & Executive Coaching in London";
	const description =
		"I understand, having worked with many lawyers and legal professionals, that you are flying solo in the business side of your firm. You may be looking for a confidential, judgement-free space where you can discuss both opportunities and challenges of your professional journey.";

	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const benG = data.benG.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Legal Professionals",
				item: {
					url: `${siteUrl}/industries/legal-professionals`,
					id: `${siteUrl}/industries/legal-professionals`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/legal-professionals`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Legal Professionals business & executive coaching in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business & Executive Coach for Lawyers, Solicitors, Attorneys, and Legal Professionals"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							{/* <p>
                As a professional in a leadership position, you’re constantly
                juggling the priorities of your job with everything else in
                life. You’re likely responsible for strategic thinking,
                overseeing operations, developing new approaches, and much more.
              </p> */}
							<p>
								I understand, having worked with many lawyers and legal
								professionals, that you are flying solo in the business side of
								your firm. You may be looking for a confidential, judgement-free
								space where you can discuss both opportunities and challenges of
								your professional journey. A business coach who understands the
								unique aspects of leading a legal practice may be what you are
								seeking. Someone to help you with the challenges of building a
								supportive team and finding the right balance between work and
								life, allowing you to expand your vision and grow your firm
								while keeping your core values intact.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Is legal professional business and executive coaching the
								answer?
							</h3>
							<p>
								With nearly four decades of business acumen including over 7
								years coaching, I've worked alongside numerous legal
								professionals who encounter the unique challenges of managing
								their law firms and leading their teams, all while pursuing a
								more fulfilling professional and personal life. Many lawyers,
								solicitors, and attorneys balance the demands of client
								representation with the intricacies of running a firm, making
								tough decisions daily, and seeking a balance that lets them
								excel both in their careers and at home. If you're a legal
								professional with a desire for positive change, looking to
								elevate your firm’s performance, or seeking a more rewarding
								legal business, my bespoke and confidential legal business and
								executive coaching services are designed to help you accomplish
								precisely that.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								Look into your firm’s future…
							</h3>

							<p>
								Picture this: you have the incredible ability to see your future
								in the legal profession with absolute clarity. Consider the
								impact on your career if you could...
							</p>
							<ul className="pl-4">
								<li>
									Become more decisive and authoritative, managing the business
									side of your legal practice with confidence?
								</li>
								<li>
									Bolster your self-assurance in every case and client meeting?
								</li>
								<li>
									Elevate your leadership and communication skills, boosting
									your team’s performance and client satisfaction?
								</li>
								<li>
									Rediscover a powerful drive for your work, inspiring
									innovative approaches in legal services and client care?
								</li>
								<li>
									Take a courageous step to invest in cutting-edge legal
									technology or firm advancement?
								</li>
							</ul>
							<p>
								Think about the profound effects these developments could have
								on your legal firm, your clientele, and your personal
								achievement. Now, reflect on this: what obstacles are standing
								in the way of turning these possibilities into your reality?
								Let’s collaborate to dismantle these barriers step by step and
								carve out a trajectory toward reaching these crucial business
								milestones.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">What keeps holding you back?</h2>
							<ul className="pl-4">
								<li>
									Are you navigating the solitary path of legal practice,
									weighed down by endless decisions and responsibilities?
								</li>
								<li>
									Contemplating crucial choices about the future of your firm
									and unsure of which route to take?
								</li>
								<li>
									Keen to delve into your core strengths and weaknesses, and
									understand how to optimise them to your advantage?
								</li>
								<li>
									Needing more hands on deck but unsure where to start with
									recruiting?
								</li>
								<li>
									Observing a drop in motivation and efficiency that once
									powered your legal services?
								</li>
								<li>
									Wanting to reignite the drive and sense of purpose within your
									legal career?
								</li>
								<li>
									Maybe you're at a standstill, looking for that breakthrough or
									a fresh perspective in your professional life?
								</li>
								<li>
									Do you have innovative ideas for elevating your firm or client
									service but need a seasoned, impartial and trusted executive
									coach to help figure them out?
								</li>
							</ul>
							<p>
								This is where my business and executive coaching for legal
								professionals steps in.
							</p>
							<p>
								In the fast-moving and rigorous realm of the legal industry,
								it's not uncommon for professionals like lawyers, solicitors,
								and attorneys to face these challenges. These are barriers that,
								over time, can affect not just your personal fulfilment as a
								business owner but also the operational success and expansion of
								your firm.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								How business and executive coaching can help you in the legal
								industry
							</h2>
							<p>
								As a business and executive coach with a focus on the legal
								sector, my goal is to guide lawyers, solicitors, attorneys, and
								legal professionals through the complex landscape of the legal
								industry. Our collaboration is designed to sharpen your
								leadership abilities, increase your firm's operational
								effectiveness, and support your career growth in the challenging
								yet dynamic legal environment.
							</p>
							<p>
								Understanding the demands of your schedule, I offer flexible
								virtual coaching sessions that hone in on aspects essential for
								a successful legal practice, such as:
							</p>
							<ul className="pl-4">
								<li>
									Personal and Professional Development for Legal Practitioners
								</li>
								<li>
									Leadership Coaching Customised for Law Firm Partners and
									Owners
								</li>
								<li>Business Strategy and Management for Legal Services</li>
								<li>Career Progression within the Legal Profession</li>
							</ul>
							<p>
								My coaching philosophy sets itself apart from the generic
								services provided by other business and executive coaches, as I
								tailor each session to address the distinctive needs of you and
								your firm. With personalised and non-judgemental advice and
								strategies, we navigate together the unique pathways and
								opportunities present in the legal world.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Why should you trust me?</h2>
							<p>
								Throughout my career as a self-employed professional, I’ve
								navigated the full spectrum of experiences that come with
								managing a business.
							</p>
							<p>
								With a strong foundation in business studies (DMS & MBA) and
								five years of specialised training in counselling and
								psychotherapy (Person-Centred and Transactional Analysis),
								combined with nearly 40 years of entrepreneurial wins, I bring a
								depth of understanding to the unique pressures and challenges
								faced by legal professionals.
							</p>
							<p>
								As a non-judgmental business coach, I appreciate the particular
								demands placed on lawyers, solicitors, attorneys, and other
								legal professionals. My coaching is tailored to harmonise your
								professional ambitions with your personal goals, offering
								confidential and expert guidance aimed at mastering the
								complexities of managing a legal practice. Together, we'll
								create your own path to professional satisfaction and work-life
								balance you desire, ensuring your practice not only succeeds but
								also upholds your standards of service and excellence you are
								committed to providing to each of your clients.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Reviews from my business and executive coaching clients
							</h2>
						</Col>
					</Row>
					<HorizontalTestimonial
						name="Ben Gottlieb. Owner at Gottlieb Law PLC"
						review="I have enjoyed working with Jason Cornes and he is an excellent business coach. As a U.S. business, I was not sure how it would work out with a U.K. business coach, but I couldn’t be happier. Jason has served as a wonderful resource to me and the firm and is always someone I can count on and puts 100% into his clients."
						linkedIn="https://www.linkedin.com/in/ben-gottlieb-038a8550/"
						profilePic={benG}
					/>
					<HorizontalTestimonial
						showLinkedIn="d-none"
						name="Stuart Long. Solicitor. Dawson Hart Solicitors."
						review="I highly recommend Jason for anyone that is in a rut and wanting to progress their business further. The discussions/meetings I have had with Jason have been both professional, personable and has given me a completely different perspective as to how I deal with business matters in the future and what I am hoping to achieve in the mid to long term. Jason is a must for anyone in need!"
						profilePic={daniel}
					/>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to work towards that positive future?
							</h2>
							<p>
								Unfortunately, though, none of us have a crystal ball. I can’t
								tell you what life will be like if you do, take that job, wake
								up filled with confidence, or grow your business tenfold - but
								what I can tell you is...
							</p>
							<p>If you do nothing, nothing will change.</p>
							<p>
								On the other hand, it’s amazing what we can achieve together
								when we use coaching to focus on what you really want.
							</p>
							<p>
								So, with nothing to lose - why not book a introductory call with
								me?
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">
								Book a call today with your new business coach
							</h3>
							<p>
								Regardless of your proficiency as a lawyer, solicitor, attorney,
								or legal practitioner, the complexities of your role are unique.
								You deserve a trusted, non-judgmental, confidential business
								coaching partnership where you can receive impartial feedback,
								establish and achieve your professional aspirations, and
								navigate challenging decisions with the correct support you
								need.
							</p>
							<p>
								Let's book a discovery call, familiarise ourselves with one
								another, and explore the possibility of working together through
								business and executive coaching tailored for legal
								professionals.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default LegalProfessionalsCoachingPage;
